<template>
  <div>
    <div class="ribbon text-ellipsis">
      <div :class="getRibbonStyle()">{{ status || 'Preview' }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { InvoiceStatusTypes } from '@/types/enums/InvoiceStatusTypes'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'StatusRibbon',
  props: {
    invoiceStatus: {
      type: String,
      required: true,
      default: () => 'Preview',
    },
  },
  setup(props) {
    const status = computed<string>(() => props.invoiceStatus || 'preview')
    const getRibbonStyle = () => {
      let ribbonStyle = {}
      switch (status.value.toLowerCase()) {
        case InvoiceStatusTypes.Draft.toLowerCase():
          ribbonStyle = {
            'ribbon-inner': true,
            'ribbon-draft': true,
          }
          break
        case InvoiceStatusTypes.Sent.toLowerCase():
          ribbonStyle = {
            'ribbon-inner': true,
            'ribbon-open': true,
          }
          break
        case InvoiceStatusTypes.Overdue.toLowerCase():
          ribbonStyle = {
            'ribbon-inner': true,
            'ribbon-overdue': true,
          }
          break
        case InvoiceStatusTypes.Paid.toLowerCase():
          ribbonStyle = {
            'ribbon-inner': true,
            'ribbon-success': true,
          }
          break
        case InvoiceStatusTypes.Void.toLowerCase():
          ribbonStyle = {
            'ribbon-inner': true,
            'ribbon-void': true,
          }
          break
        default:
          ribbonStyle = {
            'ribbon-inner': true,
            'ribbon-draft': true,
          }
      }

      return ribbonStyle

      // if (status.value.toLowerCase() === InvoiceStatusTypes.Draft.toLowerCase()) {
      //   return {
      //     'ribbon-draft': true,
      //   }
      // }
    }
    return {
      status,
      getRibbonStyle,
    }
  },
})
</script>

<style scoped>
.ribbon-flag {
  position: relative;
  background-color: #ff660f;
  padding: 6px 12px;
  color: #fff;
  font-size: 12px;
  vertical-align: middle;
}

.ribbon-flag:after {
  position: absolute;
  top: 0;
  border: 13px solid #ff660f;
  border-right-color: transparent;
  border-left-width: 12px;
  border-top: -20px;
}

.ribbon {
  position: absolute !important;
  top: -5px;
  left: -5px;
  overflow: hidden;
  width: 96px;
  height: 94px;
}

.ribbon .ribbon-inner {
  text-align: center;
  color: #fff;
  top: 24px;
  left: -31px;
  width: 135px;
  padding: 3px;
  position: relative;
  transform: rotate(-45deg);
}

.ribbon .ribbon-inner:after,
.ribbon .ribbon-inner:before {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-left-color: inherit;
  border-right: 5px solid transparent;
  border-bottom: 5px solid;
  border-bottom-color: inherit;
  position: absolute;
  top: 20px;
  transform: rotate(-45deg);
}

.ribbon .ribbon-inner:after,
.ribbon .ribbon-inner:before,
.ribbon-flag:after {
  content: '';
}

.notification-list .list-item.read,
.ribbon .ribbon-inner:before {
  border-left: 2px solid transparent;
}

.ribbon .ribbon-inner:before {
  left: 0;
}

.ribbon .ribbon-inner:after {
  right: -2px;
  border-bottom: 3px solid transparent;
}

.ribbon .ribbon-open {
  background-color: #268ddd;
  border-color: #1c72b4;
}

.ribbon .ribbon-accepted {
  background-color: #1aaf5d;
  border-color: #138345;
}

.ribbon .ribbon-success {
  background-color: #1fcd6d;
  border-color: #18a155;
}

.ribbon .ribbon-overdue {
  background-color: #f59d00;
  border-color: #c27c00;
}

.ribbon .ribbon-draft {
  background-color: #94a5a6;
  border-color: #788e8f;
}

.ribbon .ribbon-error {
  background: #c46766;
  border-color: #953b39;
}

.ribbon .ribbon-void {
  background: #474747;
  border-color: #1a1a1a;
}

.ribbon .ribbon-pending {
  background-color: #f59d00;
  border-color: #c27c00;
  padding: 5px 0;
  font-size: 11px;
}

.ribbon .ribbon-approved {
  background-color: #5bc0de;
  border-color: #31b0d5;
}

.ribbon .ribbon-signed {
  background-color: #8aa9ff;
  border-color: #5c87ff;
}

.ribbon-right {
  left: initial;
  right: -5px;
}

.ribbon-right > .ribbon-inner {
  left: initial;
  right: 7px;
  transform: rotate(45deg);
}
</style>
