
import { InvoiceFormData } from '@/types/appcontracts/InvoiceFormData'
import { InvoicesDocument } from '@/types/firebaseCollectionContracts/InvoicesDocument'
import { computed, defineComponent, PropType } from 'vue'
import * as utils from '@/services/helpers'
import dayjs from 'dayjs'
import StatusRibbon from './StatusRibbon.vue'
// import TableInvoiceConsignment from './TableInvoiceConsignments.vue'

export default defineComponent({
  name: 'InvoiceDataView',
  components: { StatusRibbon },
  props: {
    invoiceData: {
      type: Object as PropType<InvoicesDocument | InvoiceFormData>,
      required: true,
    },
  },
  setup(props) {
    const data = computed(() => props.invoiceData || {})
    const formatDate = (dt: any) => {
      return dayjs(dt).format('DD-MM-YYYY')
    }
    return {
      data,
      utils,
      formatDate,
    }
  },
})
