
// import { useStore } from 'vuex'
import { ref, defineComponent, computed, onMounted } from 'vue'
import * as actions from '@/store/actions.type'
import { SyncOutlined } from '@ant-design/icons-vue'
import InvoicesTable from './InvoicesTable.vue'
import { useStore } from 'vuex'
import { IAppDocument } from '@/types/interfaces/IAppDocument'
import { InvoicesDocument } from '@/types/firebaseCollectionContracts/InvoicesDocument'
import InvoiceView from './view/index.vue'
import InvoicesTableFilter from './InvoicesTableFilter.vue'
import { IFilterWhereQuery } from '@/types/interfaces/IFilterWhereQuery'
import { IFilterSelect } from '@/types/interfaces/IFilterSelect'

// import * as actions from '@/store/actions.type'
// import ModalSlot from '@/components/General/ModalSlot.vue'

export default defineComponent({
  name: 'Invoices',
  components: { InvoicesTable, SyncOutlined, InvoiceView, InvoicesTableFilter },
  setup() {
    const store = useStore()
    const viewInvoice = ref<boolean>(false)
    const currRecord = ref<IAppDocument<InvoicesDocument> | null>(null)
    const invoiceSearchId = ref<string | null>(null)
    const isInvoicesLoading = computed(() => store.state.invoices.invoicesLoading)
    const invoicesExists = computed(() => store.getters['invoices/invoicesExists'])
    const manualInvoiceIdList = computed(() => store.getters['companies/getManualInvoiceIdList'])
    const filterQuery = ref<IFilterWhereQuery[]>([])
    const filterTags = ref<string[]>([])
    const allInvIdList = computed(() => {
      const autoInv = store.getters['companies/getAutoInvoiceIdList']
      return [].concat(manualInvoiceIdList.value, autoInv)
    })
    const invoicesList = computed(
      () => store.getters[`invoices/invoiceList`] as IAppDocument<InvoicesDocument>[],
    )
    const reloadIconType = ref(false)
    const invoiceSearchOptions = computed(() => {
      return allInvIdList.value
        .map((id: string) => {
          return {
            key: id,
            value: id,
            label: id,
          }
        })
        .sort((a: any, b: any) => {
          return a.value.toUpperCase() < b.value.toUpperCase() ? -1 : 1
        })
    })

    const handleSearchSelect = (val: string | null) => {
      invoiceSearchId.value = val
    }
    const handleSearchChange = (val: string | null) => {
      if (!val) {
        invoiceSearchId.value = null
      }
    }
    const handleDataReload = async () => {
      reloadIconType.value = true
      await store.dispatch(`invoices/${actions.InvoicesAction.FETCH_INVOICES_ON_LOAD}`, {
        filters: filterQuery.value,
      })
      reloadIconType.value = false
    }

    const handleChange = (val: any) => {
      console.log(val)
    }

    const handleInvoiceView = (record: any) => {
      currRecord.value = record || null
      viewInvoice.value = true
    }

    const handleDeletedInvoice = () => {
      viewInvoice.value = false
    }

    const handleFilterApply = async (filterSelect: IFilterSelect) => {
      if (filterSelect) {
        filterQuery.value = filterSelect.filters
        filterTags.value = filterSelect.filterTags
        await handleDataReload()
      }
    }

    onMounted(() => {
      if (invoicesList.value.length === 0) {
        handleDataReload()
      }
    })

    return {
      isInvoicesLoading,
      invoicesExists,
      reloadIconType,
      invoicesList,
      handleDataReload,
      handleChange,
      viewInvoice,
      handleInvoiceView,
      currRecord,
      handleDeletedInvoice,
      invoiceSearchOptions,
      invoiceSearchId,
      handleSearchSelect,
      handleSearchChange,
      handleFilterApply,
      filterTags,
      filterQuery,
    }
  },
})
