<template>
  <div
    class="details-container clearfix "
    style="max-width: 8.27in;margin-left:auto;margin-right:auto; min-height: 11.7in;"
  >
    <div>
      <status-ribbon :invoice-status="data?.invoiceStatus" />
    </div>
    <div class="inv-template">
      <!-- Only to create header space -->
      <div class="inv-template-header inv-header-content">
        <div class="inv-template-fill-emptydiv"></div>
      </div>
      <div class="inv-template-body">
        <div class="inv-template-bodysection">
          <table style="width: 100%;">
            <tbody>
              <tr>
                <td
                  style="width:10%;padding: 2px 10px;vertical-align: middle;"
                  v-if="data?.company?.photoUrl"
                >
                  <img style="width:120px;height:120px;" :src="data?.company?.photoUrl" />
                </td>
                <td style="width:50%;padding: 2px 10px;vertical-align: middle;">
                  <div style="margin-top: 5px; margin-bottom: 5px;">
                    <div class="inv-orgname" style="font-weight: bold;">
                      {{ data?.company?.name || 'Power Media Systems and Services' }}
                      <!-- <br /> -->
                    </div>
                    <div>
                      <div style="white-space: pre-line;">
                        {{ utils.formatAddressObject(data?.company?.address) }}
                      </div>
                      <div>Email - {{ data?.company?.email || 'N/A' }}</div>
                      <div>Phone - {{ data?.company?.phone || 'N/A' }}</div>
                      <p>GSTIN: {{ data?.company?.gstin || 'N/A' }}</p>
                    </div>
                  </div>
                </td>
                <td style="width:40%;padding: 5px;vertical-align: bottom;text-align:right">
                  <div class="inv-entity-title">TAX INVOICE</div>
                </td>
              </tr>
            </tbody>
          </table>
          <div style="width: 100%;">
            <table
              class="invoice-detailstable"
              style="width: 100%;table-layout: fixed;word-wrap: break-word;"
              border="0"
              cellpadding="0"
              cellspacing="0"
            >
              <tbody>
                <tr>
                  <td style="border-right: 1px solid #9e9e9e;padding: 10px;">
                    <span class="inv-label">Invoice Number</span>
                    <span style="font-weight: 600;"> : {{ data?.invid }} </span>
                    <span class="inv-label">Invoice Date</span>
                    <span style="font-weight: 600;"> : {{ formatDate(data?.invoiceDate) }} </span>
                    <span class="inv-label">Place Of Supply</span>
                    <span style="font-weight: 600;">: {{ data?.placeOfSupply }}</span>
                    <span class="inv-label">SAC</span>
                    <span style="font-weight: 600;">: 996812</span>
                  </td>
                  <td style="padding: 10px;">
                    <span class="inv-label">GSTIN</span>
                    <span style="font-weight: 600;">: {{ data?.company?.gstin || 'N/A' }}</span>
                    <span class="inv-label">PAN</span>
                    <span style="font-weight: 600;">: {{ data?.company?.pan || 'N/A' }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <table cellpadding="0" cellspacing="0" border="0" class="inv-addresstable">
            <thead>
              <tr>
                <th style="border-right: 1px solid #9e9e9e;border-top: 1px solid #9e9e9e;">
                  <label class="inv-label" style="margin-bottom: 0px;display: block;">
                    <b>Bill To</b>
                  </label>
                </th>
                <th style="border-top: 1px solid #9e9e9e;">
                  <label class="inv-label" style="margin-bottom: 0px;display: block;">
                    <b>Ship To</b>
                  </label>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td valign="top" style="border-right: 1px solid #9e9e9e;">
                  <div>
                    <div>
                      <strong>
                        <span class="inv-customer-name">
                          {{ data?.customer?.name || 'N/A' }}
                        </span>
                      </strong>
                    </div>
                    <div>
                      <div style="white-space: pre-wrap;word-wrap: break-word;">
                        {{ utils.formatAddressObject(data?.customer?.billAddress) }}
                      </div>
                      <div>Customer ID - {{ data?.customer?.cid || 'N/A' }}</div>
                      <div>GSTIN: {{ data?.customer?.gstin || 'N/A' }}</div>
                      <p></p>
                    </div>
                  </div>
                </td>
                <td valign="top">
                  <div>
                    <span> GSTIN: {{ data?.customer?.gstin || 'N/A' }} </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <table
            border="0"
            cellpadding="0"
            cellspacing="0"
            class="inv-itemtable"
            style="width: 100%;table-layout:fixed;clear: both;"
          >
            <thead>
              <tr style="height:17px;">
                <td
                  class="inv-itemtable-header inv-itemtable-breakword"
                  rowspan="2"
                  valign="bottom"
                  style="padding: 5px 5px 2px 5px;width: 5%;text-align: center;"
                >
                  <b>#</b>
                </td>
                <td
                  class="inv-itemtable-header inv-itemtable-breakword"
                  rowspan="2"
                  valign="bottom"
                  style="padding: 5px 7px 2px 7px;width: 14%;text-align: left;"
                >
                  <b>Shipment Date</b>
                </td>
                <td
                  class="inv-itemtable-header inv-itemtable-breakword"
                  rowspan="2"
                  valign="bottom"
                  style="padding: 5px 7px 2px 7px;width: 18%;text-align: left;"
                >
                  <b>AWB</b>
                </td>
                <td
                  class="inv-itemtable-header inv-itemtable-breakword"
                  rowspan="2"
                  valign="bottom"
                  style="padding: 5px 7px 2px 7px;width: 14%;text-align: left;"
                >
                  <b>Refno</b>
                </td>
                <td
                  class="inv-itemtable-header inv-itemtable-breakword"
                  rowspan="2"
                  valign="bottom"
                  style="padding: 5px 7px 2px 7px;width: 14%;text-align: left;"
                >
                  <b>Dest</b>
                </td>
                <td
                  class="inv-itemtable-header inv-itemtable-breakword"
                  rowspan="2"
                  valign="bottom"
                  style="padding: 5px 7px 2px 7px;width: 8%;text-align: right;"
                >
                  <b>Pcs</b>
                </td>
                <td
                  class="inv-itemtable-header inv-itemtable-breakword"
                  rowspan="2"
                  valign="bottom"
                  style="padding: 5px 7px 2px 7px;width: 10%;text-align: right;"
                >
                  <b>Weight (kg)</b>
                </td>
                <td
                  class="inv-itemtable-header inv-itemtable-breakword"
                  rowspan="2"
                  valign="bottom"
                  style="padding: 5px 7px 2px 7px;width: 14%;text-align: right;"
                >
                  <b>Amount (INR)</b>
                </td>
              </tr>
            </thead>
            <tbody class="itemBody">
              <tr
                style="height:20px;"
                class="breakrow-inside breakrow-after"
                v-for="(con, idx) in data?.consignments"
                :key="idx"
              >
                <td class="inv-item-row" style="text-align: center;" valign="top" rowspan="1">
                  {{ idx + 1 }}
                </td>
                <!-- <td id="tmp_item_name" class="inv-item-row" valign="top" rowspan="1">
                  <div>
                    <div>
                      <span id="tmp_item_name" style="word-wrap: break-word;">
                        Courier Service
                      </span>
                    </div>
                  </div>
                </td> -->
                <td class="inv-item-row" valign="top" rowspan="1">
                  <div>
                    <div>
                      <span style="word-wrap: break-word;">
                        {{ formatDate(con?.bookingDate) }}
                      </span>
                      <br />
                      <span
                        class="inv-item-desc"
                        style="white-space: pre-line;word-wrap:break-word;"
                      ></span
                      ><br />
                    </div>
                  </div>
                </td>
                <td class="inv-item-row" style="text-align: left;" valign="top" rowspan="1">
                  {{ con?.awb }}
                </td>
                <td class="inv-item-row" style="text-align: left;" valign="top" rowspan="1">
                  {{ con?.rfn }}
                </td>
                <td class="inv-item-row" style="text-align:left;" valign="top" rowspan="1">
                  <div>{{ con?.dest }}</div>
                </td>
                <td class="inv-item-row" style="text-align:right;" valign="top" rowspan="1">
                  <div>{{ con?.pcs }}</div>
                </td>
                <td class="inv-item-row" style="text-align: right;" valign="top" rowspan="1">
                  {{ con?.weight }}
                </td>
                <td class="inv-item-row" style="text-align: right;" valign="top" rowspan="1">
                  {{ con?.amount }}
                </td>
              </tr>
            </tbody>
          </table>
          <div style="width: 100%;margin-top: 1px;">
            <div style="width: 50%;padding: 4px 4px 3px 7px;float: left;">
              <div style="margin:10px 0 5px">
                <div style="padding-right: 10px;">Total In Words</div>
                <span>
                  <b
                    ><i>{{ utils.getAmountInWords(data?.finalAmount) }}</i></b
                  >
                </span>
              </div>
              <div style="padding-top: 10px;">
                <p class="inv-notes" style="white-space: pre-line;word-wrap: break-word;">
                  Thanks for your business.
                </p>
                <br />
                <div v-if="data?.company?.bankAccount">
                  Bank Account Details:
                  <ul>
                    <li>Payee: {{ data?.company?.bankAccount?.payee }}</li>
                    <li>
                      Bank: {{ data?.company?.bankAccount?.bankName }},
                      {{ data?.company?.bankAccount?.branchName }}
                    </li>
                    <li>Account no.: {{ data?.company?.bankAccount?.acno }}</li>
                    <li>IFSC code: {{ data?.company?.bankAccount?.ifsc }}</li>
                  </ul>
                </div>
                <br />
                <div>
                  Terms & Conditions:
                  <ul>
                    <li>
                      All invoices to be paid within 7 days from the date of invoice.
                    </li>
                    <li>
                      Interest 2% per month will be charged on delayed payments.
                    </li>
                    <li>
                      All conflicts are subject to Kolkata jurisdiction.
                    </li>
                    <li>Kindly notify us immediately if you find any discrepancy.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="inv-totals" style="width: 43.6%;float:right;">
              <table
                width="100%"
                border="0"
                cellspacing="0"
                class="inv-totaltable"
                style="border-left: 1px solid #9e9e9e;"
              >
                <tbody>
                  <tr>
                    <td valign="middle">
                      Sub Total <br />
                      <!-- <span style="color:#666;font-size:10px;">
                        (Tax Exclusive)
                      </span> -->
                    </td>
                    <td style="width:110px;" valign="middle" id="tmp_subtotal">
                      {{ utils.formatToINR(data?.subTotal) }}
                    </td>
                  </tr>
                  <tr style="height:10px;" v-for="(tx, idx) in data?.appliedCharges" :key="idx">
                    <td align="right" valign="middle">{{ tx?.name }}</td>
                    <td style="width:110px;" valign="middle">{{ tx?.value }}</td>
                  </tr>
                  <tr style="height:10px;" v-if="data?.discount.value > 0">
                    <td align="right" valign="middle">
                      Discount
                    </td>
                    <td style="width:110px;" valign="middle">
                      {{ utils.formatToINR(data?.discount.amount * -1) || 'NA' }}
                    </td>
                  </tr>
                  <tr
                    style="height:10px;"
                    v-if="
                      data?.adjustment != 0 &&
                        data?.adjustment != null &&
                        data?.adjustment != undefined
                    "
                  >
                    <td align="right" valign="middle">
                      Adjustment
                    </td>
                    <td style="width:110px;" valign="middle">
                      {{ utils.formatToINR(data?.adjustment) || 'NA' }}
                    </td>
                  </tr>
                  <tr style="height:10px;" v-for="(tx, idx) in data?.appliedTaxes" :key="idx">
                    <td align="right" valign="middle">{{ tx?.name }}</td>
                    <td style="width:110px;" valign="middle">{{ tx?.value }}</td>
                  </tr>

                  <!-- TDS or CIS Label -->
                  <!-- <tr>
                    <td valign="middle"><b>Total</b></td>
                    <td style="width:110px;" valign="middle" id="tmp_total">
                      <b>{{ utils.formatToINR(data?.finalAmount) }}</b>
                    </td>
                  </tr> -->
                  <tr class="inv-balance" style="height:10px;">
                    <td valign="middle"><b>Total</b></td>
                    <td style="width:110px;;" valign="middle" id="tmp_balance_due">
                      <strong>{{ utils.formatToINR(data?.finalAmount) }}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="border-bottom: 1px solid #9e9e9e;"></td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td colspan="2" style="text-align: right;padding-top: 5px;">
                      <div style="min-height: 75px;">E &amp; OE</div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="text-align: center;border-bottom: 1px solid #9e9e9e;">
                      <label class="inv-totals" style="margin-bottom: 0px;"
                        >Authorized Signatory</label
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div style="clear: both;"></div>
            <div style="clear: both;"></div>
            <div style="text-align: center; padding-top: 5px;padding-bottom: 3px;">
              This is a computer generated invoice no signature is required.
            </div>
          </div>
        </div>
      </div>
      <div class="inv-template-footer">
        <div></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { InvoiceFormData } from '@/types/appcontracts/InvoiceFormData'
import { InvoicesDocument } from '@/types/firebaseCollectionContracts/InvoicesDocument'
import { computed, defineComponent, PropType } from 'vue'
import * as utils from '@/services/helpers'
import dayjs from 'dayjs'
import StatusRibbon from './StatusRibbon.vue'
// import TableInvoiceConsignment from './TableInvoiceConsignments.vue'

export default defineComponent({
  name: 'InvoiceDataView',
  components: { StatusRibbon },
  props: {
    invoiceData: {
      type: Object as PropType<InvoicesDocument | InvoiceFormData>,
      required: true,
    },
  },
  setup(props) {
    const data = computed(() => props.invoiceData || {})
    const formatDate = (dt: any) => {
      return dayjs(dt).format('DD-MM-YYYY')
    }
    return {
      data,
      utils,
      formatDate,
    }
  },
})
</script>

<style scoped>
/* @font-face {
  font-family: 'WebFont-Open Sans';
  src: url(https://fonts.gstatic.com/s/opensans/v14/K88pR3goAWT7BTt32Z01m4X0hVgzZQUfRDuZrPvH3D8.woff2);
} */

.details-container {
  position: relative;
  margin-bottom: 35px;
  box-shadow: 0 0 6px #ccc;
}

.inv-template {
  /* font-family: Open Sans, 'WebFont-Open Sans'; */
  font-family: 'Nunito Sans', Helvetica, Arial, sans-serif;
  font-size: 8pt;
  box-sizing: border-box;
  color: #000000;
  background: #ffffff;
}

.inv-header-content {
  font-size: 8pt;
  color: #000000;
  background-color: #ffffff;
}

.inv-template-body {
  padding: 0 0.4in 0 0.4in;
}

.inv-template-footer {
  height: 0.45in;
  font-size: 6pt;
  color: #000000;
  padding: 0 0.4in 0 0.4in;
  background-color: #ffffff;
}

.inv-footer-content {
  word-wrap: break-word;
  color: #000000;
  border-top: 1px solid #9e9e9e;
}

.inv-label {
  color: #333333;
}

.inv-entity-title {
  font-size: 22pt;
  color: #000000;
}

.inv-orgname {
  font-size: 12pt;
  color: #000000;
}

.inv-customer-name {
  font-size: 9pt;
  color: #000000;
}

.inv-eori-number {
  color: #333;
  margin: 0;
  padding-top: 10px;
}

.inv-itemtable-header {
  font-size: 8pt;
  color: #000000;
  background-color: #f2f3f4;
}

.inv-itemtable-breakword {
  word-wrap: break-word;
}

.inv-taxtable-header {
  font-size: 8pt;
  color: #000000;
  background-color: #f2f3f4;
}

.breakrow-inside {
  page-break-inside: avoid;
}

.breakrow-after {
  page-break-after: auto;
}

.inv-item-row {
  font-size: 8pt;
  border-bottom: 1px solid #9e9e9e;
  background-color: #ffffff;
  color: #000000;
}

.inv-item-sku {
  margin-top: 2px;
  font-size: 10px;
  color: #444444;
}

.inv-item-desc {
  color: #333333;
  font-size: 8pt;
}

.inv-balance {
  background-color: #ffffff;
  font-size: 9pt;
  color: #000000;
}

.inv-totals {
  font-size: 8pt;
  color: #000000;
  background-color: #ffffff;
}

.inv-notes {
  font-size: 10pt;
}

.inv-terms {
  font-size: 8pt;
}

.inv-header-first {
  background-color: #ffffff;
  font-size: 8pt;
  color: #000000;
  height: auto;
}

/* .inv-status {
  color: ;
  font-size: 15pt;
  border: 3px solid;
  padding: 3px 8px;
} */

.billto-section {
  padding-top: 0mm;
  padding-left: 0mm;
}

.shipto-section {
  padding-top: 0mm;
  padding-left: 0mm;
}

@page :first {
  @top-center {
    content: element(header);
  }

  margin-top: 0.45in;
}

.inv-template-header {
  padding: 0 0.4in 0 0.4in;
  height: 0.45in;
}

.inv-template-fill-emptydiv {
  display: table-cell;
  content: ' ';
  width: 100%;
}

/* Additional styles for RTL compat */
/* Helper Classes */
.inline {
  display: inline-block;
}

.v-top {
  vertical-align: top;
}

.text-align-right {
  text-align: right;
}

.rtl .text-align-right {
  text-align: left;
}

.text-align-left {
  text-align: left;
}

.rtl .text-align-left {
  text-align: right;
}

.float-section-right {
  float: right;
}

.rtl .float-section-right {
  float: left;
}

.float-section-left {
  float: left;
}

.rtl .float-section-left {
  float: right;
}

/* Helper Classes End */
.item-details-inline {
  display: inline-block;
  margin: 0 10px;
  vertical-align: top;
  max-width: 70%;
}

.total-in-words-container {
  width: 100%;
  margin-top: 10px;
}

.total-in-words-label {
  vertical-align: top;
  padding: 0 10px;
}

.total-in-words-value {
  width: 170px;
}

.total-section-label {
  padding: 5px 10px 5px 0;
  vertical-align: middle;
}

.total-section-value {
  width: 120px;
  vertical-align: middle;
  padding: 10px 10px 10px 5px;
}

.rtl .total-section-value {
  padding: 10px 5px 10px 10px;
}

.tax-summary-description {
  color: #727272;
  font-size: 8pt;
}

.bharatqr-bg {
  background-color: #f4f3f8;
}

/* Overrides/Patches for RTL compat */
.rtl th {
  text-align: inherit;
  /* Specifically setting th as inherit for supporting RTL */
}

/* Overrides/Patches End */
/* Signature styles */
.sign-border {
  width: 200px;
  border-bottom: 1px solid #000;
}

.sign-label {
  display: table-cell;
  font-size: 10pt;
  padding-right: 5px;
}

/* Signature styles End */
/* Subject field styles */
.subject-block {
  margin-top: 20px;
}

.subject-block-value {
  word-wrap: break-word;
  white-space: pre-wrap;
  line-height: 14pt;
  margin-top: 5px;
}

/* Subject field styles End*/
.inv-template-bodysection {
  border: 1px solid #9e9e9e;
}

.inv-itemtable {
  border-top: 1px solid #9e9e9e;
}

.inv-addresstable {
  width: 100%;
  table-layout: fixed;
}

.inv-addresstable > thead > tr > th {
  padding: 1px 15px;
  background-color: #f2f3f4;
  font-weight: normal;
  border-bottom: 1px solid #9e9e9e;
  text-align: left;
}

.inv-addresstable > tbody > tr > td {
  line-height: 15px;
  padding: 10px 5px 0px 15px;
  vertical-align: top;
  word-wrap: break-word;
}

.invoice-detailstable > tbody > tr > td {
  width: 50%;
  vertical-align: top;
  border-top: 1px solid #9e9e9e;
}

.invoice-detailstable > tbody > tr > td > span {
  width: 45%;
  padding: 3px 5px;
  display: inline-block;
  vertical-align: top;
}

.inv-itemtable-header {
  font-weight: normal;
  border-right: 1px solid #9e9e9e;
  border-bottom: 1px solid #9e9e9e;
}

.inv-itemtable-subheader {
  padding: 1px 5px;
  text-align: right;
}

.inv-item-row {
  border-right: 1px solid #9e9e9e;
  border-bottom: 1px solid #9e9e9e;
}

.inv-itemtable tr td.inv-itemtable-subheader:last-child {
  border-right: 1px solid #9e9e9e;
}

.inv-itemtable tr td.inv-itemtable-subrow:last-child {
  border-right: 1px solid #9e9e9e;
}

.inv-itemtable tr td:last-child,
.inv-itemtable tr th:last-child {
  border-right: 0px;
}

.inv-itemtable tr td:first-child,
.inv-itemtable tr th:first-child {
  border-left: 0px;
}

.inv-itemtable tbody > tr > td {
  padding: 1px 5px;
  word-wrap: break-word;
}

.inv-totaltable tbody > tr > td {
  padding: 4px 7px 0px;
  text-align: right;
}

.inv-footer-content {
  border-top: 0px;
}

#tmp_vat_summary_label {
  padding: 4px 4px 3px 7px;
}

.subject-block {
  margin-top: 0px;
  padding: 10px;
  border-top: 1px solid #9e9e9e;
}

.inv-taxtable-header {
  border-bottom: 1px solid #9e9e9e;
  border-right: 1px solid #9e9e9e;
}
</style>
