
import { computed, defineComponent, PropType, reactive, ref, watch } from 'vue'
import { InvoicesDocument } from '@/types/firebaseCollectionContracts/InvoicesDocument'
import { useStore } from 'vuex'
import { IAppDocument } from '@/types/interfaces/IAppDocument'
import { downloadBase64File, getFormattedDate } from '@/services/helpers'
import { DownloadOutlined, EyeOutlined, PrinterOutlined } from '@ant-design/icons-vue'
import * as actions from '@/store/actions.type'
import printJS from 'print-js'
import { IFilterWhereQuery } from '@/types/interfaces/IFilterWhereQuery'
// import InvoiceView from './view/index.vue'

const columns = [
  {
    title: 'Invoice#',
    dataIndex: 'doc.invid',
    key: 'invid',
  },
  {
    title: 'Company',
    dataIndex: 'doc.company.name',
    key: 'company',
  },
  {
    title: 'Invoice Date',
    dataIndex: 'doc.invoiceDate',
    key: 'invoiceDate',
    slots: { customRender: 'renderDate' },
  },
  {
    title: 'Customer',
    dataIndex: 'doc.customer.name',
    key: 'customer',
  },
  {
    title: 'Due Date',
    dataIndex: 'doc.dueDate',
    key: 'dueDate',
    slots: { customRender: 'renderDate' },
  },

  {
    title: 'Status',
    dataIndex: 'doc.invoiceStatus',
    key: 'status',
    slots: { customRender: 'invoiceStatus' },
  },
  {
    title: 'Amount',
    dataIndex: 'doc.finalAmount',
    key: 'amount',
  },
  {
    title: 'Balance Due',
    dataIndex: 'doc.balanceDue',
    key: 'balanceDue',
  },
  {
    title: 'Actions',
    key: 'actions',
    slots: { customRender: 'actions' },
  },
]

export default defineComponent({
  name: 'InvoicesTable',
  components: { DownloadOutlined, EyeOutlined, PrinterOutlined },
  props: {
    invoicesList: {
      type: Object as PropType<IAppDocument<InvoicesDocument>[] | null>,
      required: true,
    },
    invoiceSearchId: {
      type: String,
      required: false,
      default: () => null,
    },
    filters: {
      type: Object as PropType<IFilterWhereQuery[] | null>,
      required: false,
      default: () => null,
    },
  },
  emits: ['viewInvoice'],
  setup(props, { emit }) {
    const store = useStore()
    const dataSource = computed(() => props.invoicesList)
    const filterDataSource = ref<IAppDocument<InvoicesDocument>[] | null>(null)
    const invSearchId = computed(() => props.invoiceSearchId)
    const isInvoiceDownloading = ref<boolean>(false)
    const invoiceDownloadId = ref<string | null>(null)
    const invoicePrintId = ref<string | null>(null)
    const tableLoading = ref(false)

    // const viewInvoice = ref<boolean>(false)
    // const currRecord = ref<IAppDocument<InvoicesDocument> | null>(null)
    const totalTableRecords = computed(
      () => (store.getters['appGlobals/totalInvoices']() as number) || 0,
    )
    const showTotal = (total: number, range: Array<any>) => {
      return `${range[0]}-${range[1]} of ${total} items`
    }

    const formatDate = (dt: any) => {
      return getFormattedDate(dt)
    }

    const handleInvoiceDownload = async (record: any) => {
      isInvoiceDownloading.value = true
      invoiceDownloadId.value = record.id
      const base64Data: string | null = await downloadInvoiceFromServer(record)
      if (base64Data) {
        downloadBase64File(base64Data, 'application/pdf', `${record?.doc?.invid}.pdf`)
      }
      isInvoiceDownloading.value = false
      invoiceDownloadId.value = null
    }

    const handleInvoicePrint = async (record: any) => {
      invoicePrintId.value = record.id
      const base64Data: string | null = await downloadInvoiceFromServer(record)
      if (base64Data) {
        printJS({
          printable: base64Data,
          type: 'pdf',
          base64: true,
        })
      }
      invoicePrintId.value = null
    }

    const downloadInvoiceFromServer = async (record: any) => {
      const base64Data: string | null = await store.dispatch(
        `invoices/${actions.InvoicesAction.DOWNLOAD_INVOICE}`,
        {
          custId: record?.doc?.customer.id,
          invId: record?.doc?.invid,
        },
      )
      return base64Data
    }

    const handleChange = async (page: any) => {
      tableLoading.value = true
      const required: number = page.current * page.pageSize

      if (dataSource.value && required > dataSource.value.length) {
        const fetchSize = required - dataSource.value.length
        await store.dispatch(`invoices/${actions.InvoicesAction.FETCH_INVOICES_FROM_SERVER}`, {
          fetchSize,
          filters: props?.filters,
        })
      }
      tableLoading.value = false
    }

    const handleViewClick = (record: any) => {
      emit('viewInvoice', record)
      // currRecord.value = record || null
      // viewInvoice.value = true
    }

    const handleDataSearch = async () => {
      tableLoading.value = true
      if (invSearchId.value) {
        const resp: IAppDocument<InvoicesDocument> | null = await store.dispatch(
          `invoices/${actions.InvoicesAction.FETCH_INVOICES_BY_ID}`,
          {
            invid: invSearchId.value,
          },
        )
        if (resp) {
          filterDataSource.value = [resp]
        }
      } else {
        filterDataSource.value = null
      }
      tableLoading.value = false
    }

    const pagination = reactive({
      position: 'bottom',
      showSizeChanger: true,
      showQuickJumper: false,
      total: props.filters && props.filters?.length > 0 ? 0 : totalTableRecords.value || 0,
      simple: false,
      pageSize: 10,
      showTotal: showTotal,
    })

    watch(invSearchId, async () => {
      await handleDataSearch()
    })

    return {
      columns,
      pagination,
      dataSource,
      formatDate,
      handleViewClick,
      isInvoiceDownloading,
      handleInvoiceDownload,
      invoiceDownloadId,
      handleChange,
      tableLoading,
      invoicePrintId,
      handleInvoicePrint,
      filterDataSource,
      // viewInvoice,
      // currRecord,
    }
  },
})
