import { ICustomersSearchMapping } from '@/types/interfaces/ICustomersSearchMapping'
import { IVendorSearchMapping } from '@/types/interfaces/IVendorSearchMapping'
import { IVendorServiceSearchMapping } from '@/types/interfaces/IVendorServiceSearchMapping'
import { IVendorZoneSearchMapping } from '@/types/interfaces/IVendorZoneSearchMapping'
import { computed } from 'vue'
import { useStore } from 'vuex'

export const useSearchMapping = () => {
  const store = useStore()

  const vendorsSearchList = computed(
    () => store.state.vendors.vendorsSearchList as IVendorSearchMapping[],
  )
  const vendorServicesSearchList = computed(
    () => store.state.vendorServices.vendorServicesSearchList as IVendorServiceSearchMapping[],
  )
  const zonesSearchList = computed(
    () => store.state.zones.zonesSearchList as IVendorZoneSearchMapping[],
  )
  const customersSearchList = computed(
    () => store.state.customers.customersSearchList as ICustomersSearchMapping[],
  )

  const getCustomerById = (id: string | null, cid: string | null) => {
    return customersSearchList.value.find((x) => x.id === id || x.cid === cid)
  }

  const getVendorByIdAlias = (id: string | null, alias: string | null) => {
    return vendorsSearchList.value.find((x) => x.id === id || x.alias === alias)
  }

  const getServiceByIdAlias = (id: string | null, alias: string | null) => {
    const service = vendorServicesSearchList.value.find((x) => x.id === id || x.alias === alias)
    const vendor = getVendorByIdAlias(service?.vendorId || null, null)

    return {
      ...service,
      vendorName: vendor?.displayName,
      vendorAlias: vendor?.alias,
    }
  }

  const getZoneDetailsById = (id: string) => {
    const zone = zonesSearchList.value.find((x) => x.id === id)
    const service = getServiceByIdAlias(zone?.serviceId || null, null)
    const zoneDoc = {
      ...zone,
      serviceName: service?.name || null,
      serviceAlias: service.alias || null,
      vendorName: service.vendorName || null,
      vendorAlias: service.vendorAlias || null,
      vendorId: service.vendorId || null,
    }

    return zoneDoc
  }

  const zoneList = computed(() => {
    return zonesSearchList.value.map((z) => getZoneDetailsById(z.id))
  })

  const serviceList = computed(() => {
    return vendorServicesSearchList.value.map((s) => getServiceByIdAlias(s.id, null))
  })

  return {
    vendorsSearchList,
    vendorServicesSearchList,
    zonesSearchList,
    customersSearchList,
    zoneList,
    serviceList,
    getVendorByIdAlias,
    getServiceByIdAlias,
    getZoneDetailsById,
    getCustomerById,
  }
}
