<template>
  <div>
    <div class="d-flex flex-wrap border-bottom mb-3">
      <div class="mt-4 mr-auto">
        <h4>Invoices</h4>
      </div>
      <div class="d-flex flex-column justify-content-center mr-3">
        <a-tooltip title="Filter">
          <div>
            <InvoicesTableFilter @onFilterApply="handleFilterApply" />
          </div>
        </a-tooltip>
      </div>
      <div>
        <router-link
          :to="{ name: 'newInvoice' }"
          class="btn btn-success btn-with-addon text-nowrap my-3 mr-3"
        >
          <span class="btn-addon">
            <i class="btn-addon-icon fe fe fe-file-plus"></i>
          </span>
          Invoice
        </router-link>
      </div>
      <div>
        <div class="btn btn-success text-blue btn-with-addon text-nowrap my-3 mr-3">
          <span class="btn-addon">
            <i class="btn-addon-icon fe fe fe-plus"></i>
          </span>
          Credit Note
        </div>
      </div>
      <div class="dropdown my-3 mr-3">
        <a-dropdown placement="bottomLeft" :trigger="['click']">
          <a class="btn btn-light text-blue dropdown-toggle" href="javascript: void(0);">Actions</a>
          <template #overlay>
            <a-menu>
              <a-menu-item :disabled="true">
                <router-link
                  :to="{
                    name: 'importFile',
                    query: { entity: 'invoices' },
                  }"
                >
                  Import
                </router-link>
                <!-- <a href="javascript:;">Import</a> -->
              </a-menu-item>
              <a-menu-item>
                <a href="javascript:;">Export</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
      <div class="d-flex flex-column justify-content-center">
        <a-tooltip title="Refresh">
          <button type="button" class="btn btn-light" @click="handleDataReload">
            <SyncOutlined :spin="reloadIconType" />
          </button>
        </a-tooltip>
      </div>
    </div>
    <div>
      <a-skeleton :loading="isInvoicesLoading" active>
        <div
          class="text-center my-5"
          v-if="!invoicesExists && !filterQuery && filterQuery?.length <= 0"
        >
          <h3>Create Invoices here.</h3>
          <p class="text-muted">
            Create and manage your invoices, all in one place.
          </p>
          <!-- <template> -->
          <div>
            <a-empty :description="false" />
          </div>
          <!-- </template> -->
          <div>
            <div class="btn btn-success my-2 text-uppercase">
              Create New Invoice
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row">
            <div class="col-lg-3">
              <div class="mb-4">
                <a-select
                  placeholder="Select Invoice ID"
                  :show-search="true"
                  option-filter-prop="label"
                  :options="invoiceSearchOptions"
                  @select="handleSearchSelect"
                  @change="handleSearchChange"
                  style="width: 100%;"
                  allow-clear
                />
              </div>
            </div>
            <div class="col-lg-7">
              <div class="d-flex flex-wrap align-items-center mt-1">
                <a-tag v-for="(val, ix) in filterTags" :key="ix" size="large" color="blue">
                  {{ val }}
                </a-tag>
              </div>
            </div>
          </div>
          <div>
            <InvoicesTable
              :invoices-list="invoicesList"
              :invoice-search-id="invoiceSearchId"
              @viewInvoice="handleInvoiceView"
              :filters="filterQuery"
            />
          </div>
          <div>
            <div>
              <a-drawer
                placement="right"
                :closable="false"
                :width="950"
                v-model:visible="viewInvoice"
                :body-style="{ padding: 0 }"
                :destroy-on-close="true"
              >
                <div>
                  <invoice-view
                    :invoice-app-doc="currRecord"
                    @invoiceDeleted="handleDeletedInvoice"
                  />
                </div>
              </a-drawer>
            </div>
          </div>
        </div>
      </a-skeleton>
    </div>
  </div>
</template>

<script lang="ts">
// import { useStore } from 'vuex'
import { ref, defineComponent, computed, onMounted } from 'vue'
import * as actions from '@/store/actions.type'
import { SyncOutlined } from '@ant-design/icons-vue'
import InvoicesTable from './InvoicesTable.vue'
import { useStore } from 'vuex'
import { IAppDocument } from '@/types/interfaces/IAppDocument'
import { InvoicesDocument } from '@/types/firebaseCollectionContracts/InvoicesDocument'
import InvoiceView from './view/index.vue'
import InvoicesTableFilter from './InvoicesTableFilter.vue'
import { IFilterWhereQuery } from '@/types/interfaces/IFilterWhereQuery'
import { IFilterSelect } from '@/types/interfaces/IFilterSelect'

// import * as actions from '@/store/actions.type'
// import ModalSlot from '@/components/General/ModalSlot.vue'

export default defineComponent({
  name: 'Invoices',
  components: { InvoicesTable, SyncOutlined, InvoiceView, InvoicesTableFilter },
  setup() {
    const store = useStore()
    const viewInvoice = ref<boolean>(false)
    const currRecord = ref<IAppDocument<InvoicesDocument> | null>(null)
    const invoiceSearchId = ref<string | null>(null)
    const isInvoicesLoading = computed(() => store.state.invoices.invoicesLoading)
    const invoicesExists = computed(() => store.getters['invoices/invoicesExists'])
    const manualInvoiceIdList = computed(() => store.getters['companies/getManualInvoiceIdList'])
    const filterQuery = ref<IFilterWhereQuery[]>([])
    const filterTags = ref<string[]>([])
    const allInvIdList = computed(() => {
      const autoInv = store.getters['companies/getAutoInvoiceIdList']
      return [].concat(manualInvoiceIdList.value, autoInv)
    })
    const invoicesList = computed(
      () => store.getters[`invoices/invoiceList`] as IAppDocument<InvoicesDocument>[],
    )
    const reloadIconType = ref(false)
    const invoiceSearchOptions = computed(() => {
      return allInvIdList.value
        .map((id: string) => {
          return {
            key: id,
            value: id,
            label: id,
          }
        })
        .sort((a: any, b: any) => {
          return a.value.toUpperCase() < b.value.toUpperCase() ? -1 : 1
        })
    })

    const handleSearchSelect = (val: string | null) => {
      invoiceSearchId.value = val
    }
    const handleSearchChange = (val: string | null) => {
      if (!val) {
        invoiceSearchId.value = null
      }
    }
    const handleDataReload = async () => {
      reloadIconType.value = true
      await store.dispatch(`invoices/${actions.InvoicesAction.FETCH_INVOICES_ON_LOAD}`, {
        filters: filterQuery.value,
      })
      reloadIconType.value = false
    }

    const handleChange = (val: any) => {
      console.log(val)
    }

    const handleInvoiceView = (record: any) => {
      currRecord.value = record || null
      viewInvoice.value = true
    }

    const handleDeletedInvoice = () => {
      viewInvoice.value = false
    }

    const handleFilterApply = async (filterSelect: IFilterSelect) => {
      if (filterSelect) {
        filterQuery.value = filterSelect.filters
        filterTags.value = filterSelect.filterTags
        await handleDataReload()
      }
    }

    onMounted(() => {
      if (invoicesList.value.length === 0) {
        handleDataReload()
      }
    })

    return {
      isInvoicesLoading,
      invoicesExists,
      reloadIconType,
      invoicesList,
      handleDataReload,
      handleChange,
      viewInvoice,
      handleInvoiceView,
      currRecord,
      handleDeletedInvoice,
      invoiceSearchOptions,
      invoiceSearchId,
      handleSearchSelect,
      handleSearchChange,
      handleFilterApply,
      filterTags,
      filterQuery,
    }
  },
})
</script>
