
import { InvoiceStatusTypes } from '@/types/enums/InvoiceStatusTypes'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'StatusRibbon',
  props: {
    invoiceStatus: {
      type: String,
      required: true,
      default: () => 'Preview',
    },
  },
  setup(props) {
    const status = computed<string>(() => props.invoiceStatus || 'preview')
    const getRibbonStyle = () => {
      let ribbonStyle = {}
      switch (status.value.toLowerCase()) {
        case InvoiceStatusTypes.Draft.toLowerCase():
          ribbonStyle = {
            'ribbon-inner': true,
            'ribbon-draft': true,
          }
          break
        case InvoiceStatusTypes.Sent.toLowerCase():
          ribbonStyle = {
            'ribbon-inner': true,
            'ribbon-open': true,
          }
          break
        case InvoiceStatusTypes.Overdue.toLowerCase():
          ribbonStyle = {
            'ribbon-inner': true,
            'ribbon-overdue': true,
          }
          break
        case InvoiceStatusTypes.Paid.toLowerCase():
          ribbonStyle = {
            'ribbon-inner': true,
            'ribbon-success': true,
          }
          break
        case InvoiceStatusTypes.Void.toLowerCase():
          ribbonStyle = {
            'ribbon-inner': true,
            'ribbon-void': true,
          }
          break
        default:
          ribbonStyle = {
            'ribbon-inner': true,
            'ribbon-draft': true,
          }
      }

      return ribbonStyle

      // if (status.value.toLowerCase() === InvoiceStatusTypes.Draft.toLowerCase()) {
      //   return {
      //     'ribbon-draft': true,
      //   }
      // }
    }
    return {
      status,
      getRibbonStyle,
    }
  },
})
