<template>
  <div>
    <div class="d-flex flex-wrap border-bottom p-2 pl-4">
      <div class="mt-4 mr-auto">
        <h4>{{ invoiceDocData?.invid || 'Invoice' }}</h4>
      </div>
    </div>
    <div>
      <div class="bg-light p-2">
        <div class="d-flex">
          <div class="btn-ribbon">
            <a-button type="link" style="color: #212529">
              <EditOutlined />
              Edit
            </a-button>
          </div>
          <div class="btn-ribbon">
            <div>
              <a-dropdown placement="bottomLeft" :trigger="['click']" disabled>
                <a-button type="link" class="dropdown-toggle">
                  <MailOutlined />
                  Mail/SMS
                </a-button>
                <template #overlay>
                  <a-menu>
                    <a-menu-item>Send Mail</a-menu-item>
                    <a-menu-item>Send SMS</a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
          </div>
          <div class="btn-ribbon">
            <a-button type="link" disabled>
              <ShareAltOutlined />
              Share
            </a-button>
          </div>
          <div class="btn-ribbon">
            <a-button type="link" disabled>
              <BellOutlined />
              Reminders
            </a-button>
          </div>
          <div class="btn-ribbon">
            <div>
              <a-dropdown placement="bottomLeft" :trigger="['click']">
                <a-button type="link" class="dropdown-toggle" style="color: #212529">
                  <FilePdfOutlined />
                  PDF/Print
                </a-button>
                <template #overlay>
                  <a-menu>
                    <a-menu-item>PDF</a-menu-item>
                    <a-menu-item>Print</a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
          </div>
          <div class="btn-ribbon">
            <div>
              <a-dropdown placement="bottomLeft" :trigger="['click']" disabled>
                <a-button type="link" class="dropdown-toggle">
                  <DollarOutlined />
                  Record Payment
                </a-button>
                <template #overlay>
                  <a-menu>
                    <a-menu-item>Record Payment</a-menu-item>
                    <a-menu-item>Write Off</a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
          </div>
          <div class="btn-ribbon">
            <div>
              <a-dropdown placement="bottomLeft" :trigger="['click']">
                <a-button type="link" style="color: #212529">
                  <EllipsisOutlined :style="{ fontSize: '16px' }" />
                </a-button>
                <template #overlay>
                  <a-menu>
                    <a-menu-item
                      v-if="!isStatusMenuDisabled(InvoiceStatusTypes.Draft)"
                      :key="InvoiceStatusTypes.Draft"
                      @click="handleStatusMenuClick"
                      >Mark as Draft</a-menu-item
                    >
                    <a-menu-item
                      v-if="!isStatusMenuDisabled(InvoiceStatusTypes.Sent)"
                      :key="InvoiceStatusTypes.Sent"
                      @click="handleStatusMenuClick"
                      >Mark as Sent</a-menu-item
                    >
                    <a-menu-item
                      v-if="!isStatusMenuDisabled(InvoiceStatusTypes.Paid)"
                      :key="InvoiceStatusTypes.Paid"
                      @click="handleStatusMenuClick"
                      >Mark as Paid</a-menu-item
                    >
                    <a-menu-item
                      v-if="!isStatusMenuDisabled(InvoiceStatusTypes.Void)"
                      :key="InvoiceStatusTypes.Void"
                      @click="handleStatusMenuClick"
                      >Void</a-menu-item
                    >
                    <a-menu-item @click="handleInvoiceDelete" key="delete">Delete</a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="padding: 20px">
      <div id="invoiceDataView" style="margin: 30px">
        <div>
          <a-spin :spinning="isLoading">
            <div>
              <InvoicePrintPage :invoice-data="invoiceDocData" />
            </div>
          </a-spin>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
// import { InvoiceFormData } from '@/types/appcontracts/InvoiceFormData'
import { InvoicesDocument } from '@/types/firebaseCollectionContracts/InvoicesDocument'
import { computed, createVNode, defineComponent, PropType, ref } from 'vue'
import InvoicePrintPage from './InvoicePrintPage.vue'
import * as actions from '@/store/actions.type'
import {
  EditOutlined,
  MailOutlined,
  ShareAltOutlined,
  BellOutlined,
  FilePdfOutlined,
  DollarOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons-vue'
import { InvoiceStatusTypes } from '@/types/enums/InvoiceStatusTypes'
import { useStore } from 'vuex'
import { IAppDocument } from '@/types/interfaces/IAppDocument'
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { DocumentActions } from '@/types/enums/DocumentActions'
import { useRouter } from 'vue-router'
import { Modal } from 'ant-design-vue'

export default defineComponent({
  name: 'InvoiceView',
  components: {
    InvoicePrintPage,
    EditOutlined,
    MailOutlined,
    ShareAltOutlined,
    BellOutlined,
    FilePdfOutlined,
    DollarOutlined,
    EllipsisOutlined,
  },
  props: {
    invoiceAppDoc: {
      type: Object as PropType<IAppDocument<InvoicesDocument> | null>,
      required: true,
    },
  },
  emits: ['invoiceDeleted'],
  setup(props, { emit }) {
    const store = useStore()
    const isLoading = ref<Boolean>(false)
    const router = useRouter()
    const invoiceDocData = computed(() => props.invoiceAppDoc?.doc || null)
    const isStatusMenuDisabled = (menuStatus: InvoiceStatusTypes) => {
      const validMoves = {
        [InvoiceStatusTypes.Draft.toString()]: [InvoiceStatusTypes.Sent, InvoiceStatusTypes.Void],
        [InvoiceStatusTypes.Sent.toString()]: [InvoiceStatusTypes.Paid, InvoiceStatusTypes.Void],
        [InvoiceStatusTypes.Overdue.toString()]: [InvoiceStatusTypes.Paid, InvoiceStatusTypes.Void],
        [InvoiceStatusTypes.Paid.toString()]: [InvoiceStatusTypes.Sent],
        [InvoiceStatusTypes.Void.toString()]: [InvoiceStatusTypes.Draft],
      }
      const prev = invoiceDocData.value?.invoiceStatus
      const curr = menuStatus || null
      if (prev) {
        const moves = validMoves[prev]
        if (moves.indexOf(curr) >= 0) {
          return false
        }
      }
      return true
    }

    const handleStatusMenuClick = async ({ key }: any) => {
      isLoading.value = true
      const docOperation: DocumentOperation<InvoicesDocument | null> = {
        action: DocumentActions.UPDATE,
        id: props.invoiceAppDoc?.id || '',
        payload: { ...(invoiceDocData.value || {}) } as InvoicesDocument,
        audit: invoiceDocData.value?.audit,
      }
      await store.dispatch(`invoices/${actions.InvoicesAction.UPDATE_INVOICE_STATUS}`, {
        status: key,
        docOperation,
      })
      isLoading.value = false
    }

    const handleInvoiceDelete = async () => {
      Modal.confirm({
        title: 'Are you sure you want to delete the invoice ?',
        content: 'This operation cannot be reverted back.',
        icon: createVNode(ExclamationCircleOutlined),
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          isLoading.value = true
          const docOperation: DocumentOperation<InvoicesDocument | null> = {
            action: DocumentActions.DELETE,
            id: props.invoiceAppDoc?.id || '',
            payload: null,
            audit: invoiceDocData.value?.audit,
          }
          await store.dispatch(`invoices/${actions.InvoicesAction.DELETE_INVOICE}`, docOperation)
          isLoading.value = false
          router.push({ name: 'invoices' })
          emit('invoiceDeleted')
        },
        onCancel() {},
      })
    }

    return {
      invoiceDocData,
      isStatusMenuDisabled,
      InvoiceStatusTypes,
      handleStatusMenuClick,
      isLoading,
      handleInvoiceDelete,
    }
  },
})
</script>

<style scoped>
.btn-ribbon {
  border-right: 1px solid #cacaca;
}
</style>
